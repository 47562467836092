import { Center } from '@chakra-ui/layout'
import React, { forwardRef } from 'react'

import Button from './Button'
import SVG from './SVG'

const IslandButton = forwardRef(({ children, as, ...props }, ref) => {
  let more = {}
  if (as) {
    more.as = as
  }
  return (
    <Button.Transparent
      color="white"
      position="relative"
      display="inline-block"
      width="8em"
      px="0"
      fontSize="1em"
      fontWeight="700"
      ref={ref}
      {...more}
      {...props}
    >
      <SVG viewBox="0 0 149 50">
        <path d="M105.148 3.591C92.601 3.701 80.021 2.1 67.53 2.234c-10.12.102-20.246.087-30.366.07-8.12 0-19.903.182-26.17 3.947C-4.004 15.334.423 33.17 15.152 37.47c12.364 3.614 27.252 2.793 40.11 2.81h45.317c12.778 0 31.217 2.682 39.871-4.862 7.54-6.574 9.777-15.784 1.322-25.585-5.716-6.614-21.369-7.221-30.524-6.685" fill="currentColor"/>
        <path d="M1.696 20.22a48.423 48.423 0 0 0 .08 9.88c.652 6.566 5.573 12.627 13.375 14.931 12.364 3.614 27.252 2.794 40.11 2.81h45.317c12.778 0 31.217 2.69 39.871-4.862 3.257-2.84 5.526-6.18 6.37-9.849 1.106-4.806.469-12.46.469-12.46" fill="currentColor"/>
        <path d="M105.148 2.131c-6.87.056-13.734-.386-20.597-.789A308.33 308.33 0 0 0 63.98.813C57.1.87 50.173.87 43.279.861c-5.613 0-11.242-.119-16.847.276-5 .347-10.238.97-14.816 3.157a20.926 20.926 0 0 0-9.626 9.32A17.528 17.528 0 0 0 .767 25.128a17.652 17.652 0 0 0 6.231 9.794c3.901 3.156 8.63 4.506 13.535 5.398a109.965 109.965 0 0 0 18.43 1.578c6.17.095 12.341-.048 18.511 0h41.742c5.032 0 10.056.276 15.088.402 5.072.236 10.155.001 15.182-.702 4.331-.694 8.686-2.091 12.046-5.003 3.822-3.315 6.696-7.805 7.118-12.903.461-5.525-2.309-10.994-6.003-14.995-2.835-3.078-7.166-4.609-11.146-5.524a65.847 65.847 0 0 0-14.18-1.5c-2.022 0-4.044.048-6.067.143-1.91.11-1.918 3.07 0 2.959a85.248 85.248 0 0 1 12.619.182c4.22.386 8.663 1.104 12.516 2.951 4.156 1.98 7.165 5.927 8.567 10.26.692 2.128.861 4.39.491 6.596a13.946 13.946 0 0 1-2.617 6.085 18.261 18.261 0 0 1-10.175 7.103c-4.419 1.255-9.076 1.492-13.654 1.515-4.777 0-9.634-.252-14.45-.394-6.02-.182-12.054-.063-18.073-.063-12.842 0-25.692-.072-38.534 0-5.963.055-11.942.078-17.873-.363-5.406-.403-11.147-1.042-16.218-3.007-6.672-2.573-11.775-9.407-10.477-16.7a16.12 16.12 0 0 1 6.003-9.604c3.574-2.888 7.961-4.016 12.444-4.648 4.918-.59 9.87-.84 14.824-.75h19.45c6.48 0 13.105-.189 19.649.048 6.544.237 13.2.789 19.8 1.042 3.185.134 6.369.213 9.61.189 1.919 0 1.927-2.975 0-2.96l-.008-.126Z" fill="#000"/>
        <path d="M.207 20.22A37.503 37.503 0 0 0 0 24.953c0 3 .111 6.148 1.09 9.013a19.64 19.64 0 0 0 4.406 7.054 19.852 19.852 0 0 0 6.948 4.626 42.742 42.742 0 0 0 9.418 2.367 97.5 97.5 0 0 0 10.494 1.097c7.014.387 14.044.205 21.066.174 15.716-.063 31.44 0 47.156 0 5.653 0 11.297.426 16.942.457 5.509 0 11.234-.157 16.536-1.791a21.414 21.414 0 0 0 7.353-3.906 21.207 21.207 0 0 0 5.282-6.4c2.508-4.736 2.452-10.442 2.174-15.674 0-.442-.048-.876-.08-1.31-.151-1.886-3.185-1.902-2.986 0 .319 3.907.462 8.105-.39 11.948a17.422 17.422 0 0 1-3.653 7.05 17.622 17.622 0 0 1-6.418 4.725c-4.682 1.98-10 2.367-15.024 2.423-5.573.094-11.058-.269-16.591-.403-7.07-.181-14.156-.047-21.226-.04H59.64c-6.847 0-13.686.135-20.525.04-6.688-.095-13.44-.442-19.992-1.886a26.485 26.485 0 0 1-8.272-2.983 16.586 16.586 0 0 1-5.366-5.232c-2.762-4.38-2.738-9.897-2.388-14.892 0-.403.056-.79.103-1.192a1.485 1.485 0 0 0-.44-1.047 1.512 1.512 0 0 0-1.056-.436c-.393.01-.767.17-1.045.446a1.509 1.509 0 0 0-.444 1.037H.207Z" fill="#000"/>
      </SVG>
      <Center as="sapn" position="absolute" color="black" left="0" right="0" top="0" height="90%">
        {children}
      </Center>
    </Button.Transparent>
  )
})

export default IslandButton
