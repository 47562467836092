import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Box from '../../components/Box'
import Flex from '../../components/Flex'
// import Link from '../../components/Link'
import Text from '../../components/Text'
import Carousel from '../../components/Carousel'
import IslandButton from '../../components/IslandButton'
import SupportAndReport from '../../components/SupportAndReport'
import BackgroundImage from '../../components/BackgroundImage'
import { responsive } from '../../components/ThemeProvider/theme'

import Banner from '../Banner'
import Layout from '../Layout'
import icons from './icons'

import frame from './image/frame.svg'
import paper from './image/paper.svg'
import book from './image/book.svg'
import maze from './image/maze.svg'
import podcast from './image/podcast.svg'
import wall from './image/wall.svg'
import polaroid from './image/polaroid.svg'
// import RegisterModal from '../../components/RegisterModal'
import title from '../HomePage/images/sections/play.svg'
import Vimeo from './Vimeo'

const percent = (num) => `${num * 100}%`

const data = [
  {
    title: 'WhatTheData',
    text: '用「拍」的圖表！\n台灣實拍資訊圖表Instagram\n每週帶你認識有趣的台灣大小數！',
    textMt: '4rem',
    textWidth: responsive('38.4%', '27%'),
    textBottom: responsive('-8em', 'unset'),
    transform: responsive('rotate(32.2deg)', 'rotate(31.59deg)'),
    right: responsive('-26%', '-13.15%'),
    width: responsive('100%', '62.3%'),
    imgWidth: responsive('120.8%', 'auto'),
    maxWidth: responsive('28em', '50.625rem'),
    src: polaroid,
    ratio: 810 / 543,
    img: [
      <StaticImage src="./image/wtd_1.png" layout="fullWidth" />,
      <StaticImage src="./image/wtd_2.png" layout="fullWidth" />,
      <StaticImage src="./image/wtd_3.png" layout="fullWidth" />,
    ],
    frame: <BackgroundImage src={frame} ratio={1} />,
    framePosition: {
      top: percent(126 / 543),
      // bottom: percent(163 / 738),
      right: percent(463 / 810),
      left: percent(36 / 810),
    },
    imgPosition: {
      top: percent(130 / 543),
      // bottom: percent(163 / 738),
      right: percent(467 / 810),
      left: percent(40 / 810),
    },
    href: 'https://www.instagram.com/whatthedata/',
    buttonText: '看更多趣數',
  },
  {
    title: '數據識讀指南',
    text: '數據會說話？\n數據會說謊？\n跟著數據識讀指南的引導\n認識數據、讀懂數據、成為數據好朋友！',
    textWidth: responsive('42.9%', '27%'),
    textBottom: responsive('-3.5em', 'unset'),
    mt: responsive('10em', '5em'),
    mr: responsive('0', '6.75rem'),
    ml: responsive('0', '1rem'),
    alignItems: 'flex-end',
    left: responsive('-45%', 'unset'),
    width: responsive('98.67%', '50.8%'),
    maxWidth: '38em',
    transform: responsive('rotate(21.47deg)', 'rotate(18.85deg)'),
    radius: '1.5em',
    src: maze,
    ratio: 1,
    img: [
      <StaticImage src="./image/cover.png" layout="fullWidth" />,
      <StaticImage src="./image/raiders.png" layout="fullWidth" />,
      <StaticImage src="./image/checklist.png" layout="fullWidth" />,
    ],
    imgPosition: {
      top: percent(94 / 661),
      right: percent(94 / 661),
      left: percent(94 / 661),
      bottom: percent(94 / 661),
    },
    to: 'datafriend',
    buttonText: '詳閱指南',
  },
  {
    title: '臺灣數據百閱',
    text: '每個折線圖的背後\n是我們生活的高低起伏\n『臺灣，你好嗎？』\n『這二十年來，你過得好嗎？』',
    textWidth: responsive('53.6%', '22%'),
    textBottom: responsive('-3.5em', 'unset'),
    transform: 'rotate(-17.79deg)',
    mt: responsive('5.625em', '2.5em'),
    right: responsive('-50% ', 'unset'),
    mr: responsive('0', '-8.5rem'),
    ml: responsive('0', '10.625rem'),
    width: responsive('125%', '61%'),
    maxWidth: '49.625em',
    // src: taiwan100,
    video: '619055918',
    // ratio: 795 / 637.5,
    ratio: 1280 / 1016,
    alignItems: 'flex-end',
    to: 'taiwan100',
    buttonText: '前往探索',
  },
  {
    title: '圖表好朋友',
    text: '圓形、方形、線形，你最喜歡什麼形狀？\n讓圖表家族為你自我介紹！',
    textWidth: responsive('48%', '26.9%'),
    textBottom: responsive('-4.5em', 'unset'),
    transform: responsive('rotate(12deg)', 'rotate(15deg)'),
    mt: responsive('5em', '5.625em'),
    mr: responsive('0', '6rem'),
    px: responsive('0', '7.5%'),
    left: responsive('-50%', 'unset'),
    width: responsive('120%', '54%'),
    maxWidth: '44.625em',
    src: book,
    ratio: 1,
    alignItems: 'flex-end',
    to: '/subscribe',
    // as: p => <RegisterModal.Button {...p} title="搶先預約「圖表好朋友」上線通知" text={`圖表好朋友預計在11月正式上線！\n留下你的E-mail，島懶員就會優先通知你！`} />,
    buttonText: '搶先登記',
    comingSoon: true,
  },
  {
    title: '街頭議數',
    text: `我們帶數據上街頭了！\n在日常中看見數據\n在數據中看見日常`,
    textWidth: responsive('49%', '26.9%'),
    textBottom: responsive('0%', 'unset'),
    src: wall,
    mt: '7.875em',
    right: responsive('-40%', '-5%'),
    maxWidth: '41em',
    img: [
      <StaticImage src="./image/taipei_1.png" layout="fullWidth" />,
      <StaticImage src="./image/taipei_2.png" layout="fullWidth" />,
      <StaticImage src="./image/taipei_3.png" layout="fullWidth" />,
    ],
    frame: <BackgroundImage src={paper} ratio={229 / 295} />,
    framePosition: {
      top: percent(13 / 374),
      bottom: percent(70 / 374),
      right: percent(170 / 550),
      left: percent(152 / 550),
    },
    imgPosition: {
      top: percent(24 / 374),
      bottom: percent(70 / 374),
      right: percent(182 / 550),
      left: percent(165 / 550),
    },
    ratio: 550 / 374,
    to: '/subscribe',
    // as: p => <RegisterModal.Button {...p} title="搶先預約「街頭議數」上線通知" text={`街頭議數預計在10月正式上線！\n留下你的E-mail，島懶員就會優先通知你！`} />,
    buttonText: '搶先登記',
    comingSoon: true,
  },
  {
    title: '台灣發生什麼數\nPodcast',
    text: '5分鐘聽懂一件數！\n用數據回應生活難題！',
    textWidth: responsive('38.9%', '25.2%'),
    textBottom: responsive('1.5em', 'unset'),
    mt: responsive('3.5em', '-7.5em'),
    mr: responsive('0', '-2.625rem'),
    ml: responsive('0', '-10.5rem'),
    left: responsive('-25%', 'unset'),
    width: responsive('100%', '75.38%'),
    transform: responsive('scale(1.28)', 'none'),
    src: podcast,
    ratio: 981 / 992,
    maxWidth: '61.25em',
    to: '/subscribe',
    // as: p => <RegisterModal.Button {...p} title="搶先預約「台灣發生什麼數 Podcast」上線通知" text={`台灣發生什麼數 Podcast預計在10月推出試播集！\n留下你的E-mail，島懶員就會優先通知你！`} />,
    buttonText: '搶先登記',
    comingSoon: true,
  },
]

const Play = () => {
  return (
    <Layout position="relative" title="好玩遊樂數" bg="custom.tiffanyGreenBg">
      <Banner
        reverse
        title={<BackgroundImage src={title} ratio={457 / 93} />}
        photoWeb={<StaticImage src="./image/banner-mobile.png" layout="fullWidth" />}
        photo={<StaticImage src="./image/banner-web.png" layout="fullWidth" />}
        logoRight={responsive('-1em', 0)}
        text={"好玩是這裡最重要的數！帶上你的好奇心，\n一起用不同的玩法探索台灣發生什麼數！"}
        textWidth="50%"
      />
      <Box pt={responsive('10.5em', '6.875em')}>
        <Box.Relative zIndex={3}>
          {data.map((d, i) => (
            <Flex
              width="auto"
              px={d.px}
              mt={d.mt}
              position="relative"
              alignItems={d.alignItems || 'center'}
              justifyContent="flex-end"
              flexDirection={i % 2 ? 'row-reverse' : 'row'}
              key={i}
            >
              <Box
                mt={responsive('0', d.textMt)}
                width={d.textWidth}
                position={responsive('absolute', 'static')}
                left={!(i % 2) && responsive('6.1%', 'unset')}
                right={i % 2 && responsive('0', 'unset')}
                bottom={d.textBottom}
                zIndex={5}
              >
                <Text.SectionTitle>{d.title}</Text.SectionTitle>
                <Text
                  fontSize={responsive('0.875em', '1.125rem')}
                  whiteSpace="pre-wrap"
                  lineHeight="2"
                >{d.text}</Text>
                <IslandButton
                  mt={responsive('0.75rem', '1rem')}
                  color="custom.buttonYellow"
                  to={d.to}
                  href={d.href}
                  // as={d.as}
                >{d.buttonText}</IslandButton>
              </Box>
              <Box.Relative
                left={d.left}
                right={d.right}
                mr={d.mr}
                ml={d.ml}
                width={d.width || responsive('100%', '50%')}
                maxWidth={d.maxWidth}
                transform={d.transform}
                opacity={d.comingSoon ? 0.7 : 1}
                style={{ filter: d.comingSoon && `grayscale(100%)` }}
              >
                {d.src && (
                  <BackgroundImage position="relative" width={d.imgWidth} src={d.src} ratio={d.ratio}>
                    {d.img && (
                      <Box.Absolute borderRadius={d.radius} overflow="hidden" {...d.imgPosition}>
                        <Carousel nodot autoplay silderProps={{ fade: true }} arrows={false}>
                          {d.img.map((p, i) => (
                            <Box key={i}>
                              {p}
                            </Box>
                          ))}
                        </Carousel>
                      </Box.Absolute>
                    )}
                    {d.frame && (
                      <Box.Absolute {...d.framePosition}>{d.frame}</Box.Absolute>
                    )}
                  </BackgroundImage>
                )}
                {d.video && (
                  <Vimeo id={d.video} ratio={d.ratio} />
                )}
              </Box.Relative>
            </Flex>
          ))}
        </Box.Relative>
        <SupportAndReport pb="3.75em" />
      </Box>
      {icons.map((d, k) => (
        <BackgroundImage
          position="absolute"
          {...d}
          key={k}
        />
      ))}
    </Layout>
  )
}

export default Play
