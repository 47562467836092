import React from 'react'
import { useMeasure } from 'react-use'

const Vimeo = ({ id, ratio }) => {
  const [measureRef, { width }] = useMeasure()
  return (
    <div ref={measureRef}>
      <iframe title={id} src={`https://player.vimeo.com/video/${id}?background=1`} width={width} height={width / ratio} frameborder="0" allow="autoplay" />
    </div>
  )
}

export default Vimeo
