import React from 'react'
import { Stack } from '@chakra-ui/layout'

import Box from '../../components/Box'
import Text from '../../components/Text'
import Flex from '../../components/Flex'
import BackgroundImage from '../../components/BackgroundImage'
import { Media, responsive } from '../../components/ThemeProvider/theme'

import arrow from './short-arrow.svg'

const Banner = ({
  photo,
  title,
  photoWeb,
  text,
  reverse,
  textWidth,
  logoleft,
  logoRight,
  textLeft,
  textBottom,
  ...props
}) => {
  return (
    <Flex
      pt="0.5em"
      position="relative"
      justifyContent="center"
      alignItems="center"
      flexDirection={reverse ? 'row-reverse' : 'row'}
      zIndex={5}
      overflow="hidden"
      {...props}
    >
      <Box.Relative
        mr={!reverse && responsive(0, '5.75em')}
        ml={reverse && responsive(0, '4em')}
        left={logoleft}
        right={logoRight}
        width={responsive('100%', '36%')}
        maxWidth={responsive('unset', '29.25em')}
      >
        <Media at="mobile">
          {photo}
        </Media>
        <Media greaterThan="mobile">
          {photoWeb}
        </Media>
      </Box.Relative>
      <Stack
        position={responsive('absolute', 'static')}
        bottom={textBottom || responsive('0', 'unset')}
        left={reverse ? responsive('6.1%', 'unset') : textLeft || responsive('44.5%', 'unset')}
        width={responsive(textWidth, 'auto')}
      >
        <Box display={responsive('none', 'block')} maxWidth="36em" mb="1em">
          {title}
        </Box>
        <Text
          fontWeight="bold"
          fontSize={responsive('1em', '1.25em')}
          lineHeight="1.6"
          whiteSpace="pre-wrap"
        >
          {text}
        </Text>
        <Box display={responsive('none', 'block')} pt="5vh">
          <BackgroundImage width="1em" mx="auto" src={arrow} ratio={18 / 66} />
        </Box>
      </Stack>
    </Flex>
  )
}

export default Banner
