import React from 'react'
import { navigate } from 'gatsby-link'

import Box from '../Box'
import Flex from '../Flex'
import BackgroundImage from '../BackgroundImage'
import { responsive } from '../ThemeProvider/theme'

import report from './report.svg'
import support from './support.svg'

const percent = (num) => `${num * 100}%`

const SupportAndReport = (props) => {
  return (
    <Flex
      flexDirection={responsive('column', 'row')}
      pt={responsive('5.25em', '10.25em')}
      pb={responsive('2.25em', '4.5em')}
      overflow="hidden"
      {...props}
    >
      <Box.Relative left={responsive('-3.25em', 0)} height="100%" width={responsive('100%', '50%')} mr="2.5em">
        <BackgroundImage src={support} ratio={642 / 345} />
        <Box.Absolute
          borderRadius="full"
          left={percent(367 / 642)}
          right={percent(40 / 642)}
          top={percent(43 / 345)}
          bottom={percent(49 / 345)}
          overflow="hidden"
          onClick={() => { navigate('/souvenir/') }}
          cursor="pointer"
        />
      </Box.Relative>
      <Box.Relative right={responsive('-4.75em', 0)} mt={responsive(0, '11em')} height="100%" width={responsive('100%', '50%')}>
        <BackgroundImage src={report} ratio={605 / 420} />
        <Box.Absolute
          left={percent(0 / 650)}
          right={percent(377 / 650)}
          top={percent(58 / 420)}
          bottom={percent(55 / 420)}
          onClick={() => { navigate('/2022計畫介紹/') }}
          cursor="pointer"
        />
      </Box.Relative>
    </Flex>
  )
}

export default SupportAndReport
