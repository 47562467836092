import { responsive } from "../../components/ThemeProvider/theme";

import line from './image/line.svg'
import nine from './image/nine.svg'
import radar from './image/radar.svg'
import blocks from './image/blocks.svg'
import percentage from './image/percentage.svg'
import magnet from './image/magnet.svg'

// 5891 4398
export default [
  {
    width: responsive('89%', '25.7%'),
    maxWidth: '21em',
    left: responsive('-4.3%', '7.8%'),
    top: responsive('17.12%', '13%'),
    src: line,
    ratio: 335 / 277
  },
  {
    width: responsive('41%', '23.3%'),
    maxWidth: '18.875em',
    left: responsive('59.7%', '76.8%'),
    top: responsive('31%', '27%'),
    src: nine,
    ratio: 302 / 304
  },
  {
    width: responsive('51.5%', '23.8%'),
    maxWidth: '19.375em',
    left: responsive('49%', '80.38%'),
    top: responsive('53.5%', '50.78%'),
    src: radar,
    ratio: 1
  },
  {
    width: responsive('36.4%', '13.6%'),
    maxWidth: '11.125em',
    left: responsive('70.4%', '-0.8%'),
    top: responsive('73.5%', '67.3%'),
    src: percentage,
    ratio: 178 / 175
  },
  {
    width: responsive('0', '15.4%'),
    maxWidth: '12.5em',
    left: responsive('0', '79.2%'),
    top: responsive('0', '81.8%'),
    src: blocks,
    ratio: 200 / 236
  },
  {
    width: responsive('53.87%', '0'),
    maxWidth: '12.625em',
    left: responsive('-14.4%', '0'),
    top: responsive('42.75%', '0'),
    src: magnet,
    ratio: 202 / 196
  },
]
